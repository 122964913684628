import { gql } from '@apollo/client';

import { LOCALIZED_STRING_FRAGMENT } from '@store/fragments';

export const POLL_DESCRIPTION_FRAGMENT = gql`
	fragment pollDescription on BodyTopElement {
		type
		value {
			... on BodyParagraph {
				elements {
					type
					value {
						... on BodyText {
							text
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
								color
							}
						}
					}
				}
			}
			... on BodyList {
				style
				type
				items {
					elements {
						type
						value {
							... on BodyText {
								text
								format {
									bold
									italic
									color
								}
							}
							... on BodyLink {
								text
								href
								format {
									bold
									italic
									color
								}
							}
						}
					}
				}
			}
		}
	}
`;

const TAGS_QUERIES = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment TagsQueryFragment on Tag {
		id
		title {
			...localizedString
		}
		hru
		type
		logo {
			url
		}
		sport {
			path
		}
		extra {
			... on TagTeamExtra {
				type
			}
		}
		statObject {
			... on statPlayer {
				id
				tag {
					hru
				}
				name: coalesceName(names: ["name", "lastName", "firstName"])
				coverage {
					isCovered: career
				}
				avatar {
					main: resize(width: "60", height: "60")
				}
			}
			... on statTeam {
				id
				tag {
					hru
				}
				name
				type
				coverage {
					roster
					matches
					isCovered: matches
				}
				logo {
					main: resize(width: "60", height: "60")
				}
			}
			... on statTournament {
				id
				tag {
					hru
				}
				name
				coverage {
					isCovered: season
				}
				logo {
					main: resize(width: "60", height: "60")
				}
			}
		}
	}
`;

const COMMENT_FRAGMENT = gql`
	fragment CommentFragment on Comment {
		id
		text
		created
		objectId
		isDeleted
		edited
		isReplyToTopLevelComment
		user {
			name
			avatar {
				url
			}
			id
			roles {
				ID
			}
		}
		objectClass
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		threadCommentsCount
		childThread(
			input: { sort: OLDEST, pagination: { ByCursor: { first: 3 } } }
		) {
			items {
				id
				text
				created
				threadId
				edited
				isDeleted
				isReplyToTopLevelComment
				objectClass
				objectId
				user {
					id
					name
					avatar {
						url
					}
					roles {
						ID
					}
				}
				reactions {
					userReaction {
						objectClass
						objectID
						reactionType
					}
					likeCount
					dislikeCount
				}
				parentComment {
					id
					isDeleted
					user {
						id
						name
					}
				}
				threadComment {
					id
					user {
						id
						name
					}
				}
			}
			paginationInfo {
				... on PaginationByCursor {
					totalItemsCount
					endCursor
					hasNextPage
				}
			}
		}
	}
`;

export const pollQueries = {
	refreshPoll: gql`
		query getPollById($pollHruOrId: ID!, $lang: Language) {
			pollQueries {
				get(input: { contentIDOrHRU: $pollHruOrId }) {
					__typename
					id
					status
					isVoted
					totalVotes
					commentsCount
					votingStatus
					options {
						... on CustomPollOption {
							optionID
							optionText {
								defaultValue(language: $lang)
							}
							pictureURL
							votes
							votesPercentage
							isChosen
						}
						... on TagPollOption {
							optionID
							tag {
								id
								title {
									defaultValue(language: $lang)
								}
								logo {
									url
								}
								type
							}
							votes
							votesPercentage
							isChosen
						}
					}
				}
			}
		}
	`,
	getPollById: gql`
		${POLL_DESCRIPTION_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		${COMMENT_FRAGMENT}
		${TAGS_QUERIES}
		query getPollById($pollHruOrId: ID!, $lang: Language) {
			pollQueries {
				get(input: { contentIDOrHRU: $pollHruOrId }) {
					__typename
					pollID
					id
					status
					isVoted
					languages
					totalVotes
					commentsCount
					mainSport {
						id
						path
						name {
							defaultValue(language: $lang)
						}
					}
					shareInfo {
						picture {
							url
						}
					}
					sports {
						id
						path
						name {
							...localizedString
						}
					}
					tags {
						...TagsQueryFragment
					}
					configurationStatus
					pollType
					authPollType
					hru
					link
					votingStatus
					publicationTime
					modifiedTime
					endsAt
					title {
						defaultValue(language: $lang)
					}
					seo {
						title
						description
						canonical
					}
					structuredDescription {
						defaultValue(language: $lang) {
							...pollDescription
						}
					}
					options {
						... on CustomPollOption {
							optionID
							optionText {
								defaultValue(language: $lang)
							}
							pictureURL
							votes
							votesPercentage
							isChosen
						}
						... on TagPollOption {
							optionID
							tag {
								id
								title {
									defaultValue(language: $lang)
								}
								logo {
									url
								}
								type
							}
							votes
							votesPercentage
							isChosen
						}
					}

					topLevelComments(
						input: { sort: BEST, pagination: { ByCursor: { first: 3 } } }
					) {
						items {
							...CommentFragment
						}

						paginationInfo {
							... on PaginationByCursor {
								totalItemsCount
								endCursor
							}
						}
					}
					author {
						user {
							id
							name
							nickname
							avatar {
								url
							}
						}
					}
					displayAuthor {
						user {
							id
							name
							nickname
							avatar {
								url
							}
						}
					}
					main
					mainSection
				}
			}
		}
	`,
};
